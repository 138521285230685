<template>
  <div>
    <v-card
      v-if="trackingListTable1.length != 0"
      tile
      :flat="$vuetify.breakpoint.mdAndDown"
      :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'"
    >
      <v-toolbar
        :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'"
        :flat="!$vuetify.breakpoint.smAndDown"
        :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
      >
        <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
          <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
            {{ icons.mdiOrderNumericAscending }}
          </v-icon>
          {{ $t('siparisTakip') }}
        </v-toolbar-title>
      </v-toolbar>

      <!-- table -->
      <v-data-table
        id="element-to-pdf"
        v-model="selectedRows"
        :headers="tableColumns1"
        :items="trackingListTable1"
        :options.sync="options"
        :items-per-page="15"
        fixed-header
        :height="dataTableHeight1"
        :loading="loading1"
      >
        <!-- Text -->
        <template #[`item.id`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
            {{ item.id }}
          </div>
          <span v-else class="text-caption">{{ item.id }}</span>
        </template>

        <template #[`item.fatura_adresi.name`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
            {{ item.fatura_adresi.name }}
          </div>
          <div v-else class="text-caption text-truncate" style="max-width: 330px">
            {{ item.fatura_adresi.name }}
          </div>
        </template>

        <template #[`item.durum`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-3">
            <v-chip
              :color="statusFind('siparisDurum', item.durum, 'color')"
              :outlined="statusFind('siparisDurum', item.durum, 'outlined')"
            >
              {{ statusFind('siparisDurum', item.durum, 'text') }}
            </v-chip>
          </div>

          <v-chip
            v-else
            :color="statusFind('siparisDurum', item.durum, 'color')"
            :outlined="statusFind('siparisDurum', item.durum, 'outlined')"
            :close-icon="icons.mdiRefresh"
            class="special"
            label
            @click:close="statusChange(item.id, item.durum)"
          >
            <span
              :class="
                statusFind('siparisDurum', item.durum, 'outlined')
                  ? null
                  : `${statusFind('siparisDurum', item.durum, 'color')}-dark--text`
              "
            >
              {{ statusFind('siparisDurum', item.durum, 'text') }}</span
            >
          </v-chip>
        </template>

        <template #[`item.siparis_tarihi`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2 font-weight-bold">
            {{ item.siparis_tarihi.slice(0, 16) }}
          </div>
          <span v-else class="text-caption font-weight-bold"> {{ item.siparis_tarihi.slice(0, 16) }}</span>
        </template>

        <template #[`item.odeme_tipi`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2">
            {{ statusFind('odemeDurum', item.odeme_tipi, 'text') }}
          </div>
          <span v-else class="text-caption font-weight-bold">
            {{ statusFind('odemeDurum', item.odeme_tipi, 'text') }}
          </span>
        </template>

        <template #[`item.total_price`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="text-body-1 my-2 success--text font-weight-bold">
            {{ item.total_price | currency }}
          </div>
          <span v-else class="success--text text-caption font-weight-bold"> {{ item.total_price | currency }}</span>
        </template>

        <template #[`item.actions`]="{ item }">
          <div v-if="$vuetify.breakpoint.xsOnly" class="pb-8 pt-4">
            <v-btn small plain class="text-capitalize mr-3" outlined @click="pdfDownload(item.id)"> İndir </v-btn>
            <v-btn
              depressed
              color="secondary"
              small
              class="text-capitalize"
              :to="{ name: 'order-tracking-view', params: { id: item.id } }"
            >
              {{ $t('ayrintilar') }}
            </v-btn>
          </div>
          <div v-else>
            <v-tooltip bottom color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  color="nightDark"
                  x-small
                  class="rounded-r-0 rounded-l-xl"
                  :to="{ name: 'order-tracking-view', params: { id: item.id } }"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>
                    {{ icons.mdiEye }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('ayrintilar') }}</span>
            </v-tooltip>
            <!-- 
          <v-tooltip bottom color="secondary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                color="nightDark"
                x-small
                class="rounded-0"
                :loading="loadingItemsPdfStatus(item.id)"
                :disabled="disabledItemsPdf"
                v-bind="attrs"
                @click="pdfDownload(item.id)"
                v-on="on"
              >
                <v-icon small>
                  {{ icons.mdiDownload }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('indir') }}</span>
          </v-tooltip>
  
          --></div>
        </template>
      </v-data-table>
    </v-card>

    <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
      <v-toolbar
        :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
        :flat="!$vuetify.breakpoint.smAndDown"
        :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
      >
        <v-toolbar-title class="text-body-2 text-md-h6 font-weight-medium">
          <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
            {{ icons.mdiFileDocument }}
          </v-icon>
          {{ $t('acikSiparisler') }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <download-excel
          v-if="!$vuetify.breakpoint.smAndDown"
          :data="json_data"
          :fields="dil == 'tr' ? json_fields : json_fields_en"
          :name="dil == 'tr' ? 'ACIK_SIPARISLER__HATKO.xls' : 'OPEN_ORDERS__HATKO.xls'"
        >
          <v-btn plain text small>
            <v-icon left>
              {{ icons.mdiDownload }}
            </v-icon>
            {{ $t('indir') }}
          </v-btn>
        </download-excel>
      </v-toolbar>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :items-per-page="-1"
        fixed-header
        :height="dataTableHeight"
        :loading="loading"
      >
        <template #[`item.DOCTYPE`]="{ item }">
          <v-chip label small>
            {{ item.DOCTYPE }}
          </v-chip>
        </template>
        <template #[`item.DOCNUM`]="{ item }">
          <v-chip color="gray" label small>
            <div class="black--text font-weight-medium">
              {{ item.DOCNUM }}
            </div>
          </v-chip>
        </template>
        <template v-slot:header.ERPSTATETXT="{ header }">
          <div>
            <v-text-field
              v-model="ERPSTATETXT"
              hide-details
              filled
              background-color="grey"
              :prepend-icon="icons.mdiMagnify"
              :label="$t('durum')"
            ></v-text-field>
          </div>
        </template>
        <template #[`item.ERPSTATETXT`]="{ item }">
          <v-chip v-if="item.ERPSTATECODE == '0'" color="error" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '1'" color="success" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '2'" color="info" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '3'" color="tertiary" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '4'" color="primary" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '5'" color="success" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '6'" color="success" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
          <v-chip v-if="item.ERPSTATECODE == '7'" color="success" label small>
            {{ item.ERPSTATETXT }}
          </v-chip>
        </template>
        <template #[`item.ITEMNUM`]="{ item }">
          <div class="text-caption font-weight-medium"></div>
          {{ item.ITEMNUM }}
        </template>

        <template #[`item.MTEXT`]="{ item }">
          <div class="text-caption font-weight-medium">
            {{ item.MTEXT }}
          </div>
        </template>
        <template #[`item.SPRICE`]="{ item }">
          <div class="secondary--text text-caption font-weight-medium">
            {{ item.SPRICE | currency }}
          </div>
        </template>
        <template #[`item.PRICEFACTOR`]="{ item }">
          <div class="secondary--text text-caption font-weight-medium">
            {{ item.PRICEFACTOR }}
          </div>
        </template>
        <template #[`item.GRANDTOTALITEM`]="{ item }">
          <div class="success--text text-caption font-weight-medium">
            {{ item.GRANDTOTALITEM | currency }}
          </div>
        </template>

        <template #[`item.PRODDATE`]="{ item }">
          <div class="error--text text-caption font-weight-medium">
            {{ item.PRODDATE }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { mdiClose, mdiDownload, mdiMagnify, mdiEye, mdiFileDocument, mdiFilter, mdiSend } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import documentsStoreModule from './documentsStoreModule'
import i18n from '@/plugins/i18n'
import trackingStoreModule from '../orderTracking/trackingStoreModule'

export default {
  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-tracking'

    const tableColumns = ref([
      {
        text: i18n.t('siparisTipi'),
        value: 'DOCTYPE',
        sortable: false,
        width: '150px',
      },
      {
        text: 'B2B NO',
        value: 'B2BREFNO',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('durum'),
        value: 'ERPSTATETXT',
        sortable: false,
        width: '150px',
        filter: value => {
          if (!ERPSTATETXT.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(ERPSTATETXT.value.toLocaleUpperCase('TR')) !== -1
        },
      },

      {
        text: i18n.t('siparisNo'),
        value: 'DOCNUM',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('kalemNo'),
        value: 'ITEMNUM',
        sortable: false,
        width: '100px',
      },

      {
        text: i18n.t('malzeme'),
        value: 'MATERIAL',
        sortable: false,
        width: '150px',
      },

      {
        text: i18n.t('malzemeAciklamasi'),
        value: 'MTEXT',
        sortable: false,
        width: '200px',
      },
      {
        text: i18n.t('acikSiparisMiktari'),
        value: 'AVAILQTY',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('kayitTarihi'),
        value: 'CREATEDAT',
        sortable: false,
        width: '200px',
      },
      {
        text: i18n.t('musterininIstedigiTarih'),
        value: 'DESDLVDATE',
        sortable: false,
        width: '200px',
      },
      {
        text: i18n.t('musteriyeVerilenTarih'),
        value: 'PRODDATE',
        sortable: false,
        width: '200px',
      },
      {
        text: i18n.t('musteriUrunKodu'),
        value: 'HTKCUSMAT',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('musteriSiparisNo'),
        value: 'CUSTORDERNUM',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('belgeFiyat'),
        value: 'SPRICE',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('belgeFiyatBirim'),
        value: 'PRICEFACTOR',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('belgeGenelToplam'),
        value: 'GRANDTOTALITEM',
        sortable: false,
        width: '150px',
      },
      {
        text: i18n.t('belgeParaBirimi'),
        value: 'CURRENCYITEM',
        sortable: false,
        width: '150px',
      },
    ])
    const tableColumns1 = ref([
      {
        text: i18n.t('satisNo'),
        value: 'id',
        width: '100px',
      },
      {
        text: i18n.t('siparisTarihi'),
        value: 'siparis_tarihi',
        width: '150px',
      },
      {
        text: i18n.t('durum'),
        value: 'durum',
      },

      // {
      //   text: 'Müşteri',
      //   value: 'adsoyad',
      //   align: 'left min-width-400',
      // },

      {
        text: i18n.t('fiyat'),
        value: 'total_price',
        align: 'right',
        sortable: false,
        width: '170px',
      },

      /*
        {
          text: 'Ödeme Tipi',
          value: 'odeme_tipi',
          width: '120px',
        },
*/
      // {
      //   text: 'Aşama',
      //   value: 'step',
      //   width: '130px',
      //   sortable: false,
      // },

      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false,
        width: '150px',
      },
    ])

    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, trackingStoreModule)
    }

    const statusFind = (arr, value, select) => store.state?.tanimlar?.[arr].find(item => item.value === value)?.[select]

    const trackingListTable1 = ref([])
    const filter = ref({
      startDate: new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 60 * 60 * 100).toISOString().substr(0, 10),
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      docStatuses: '',
      docCustomer: '',
      docMaterial: '',
    })
    const isFilterUserSidebarActive = ref(false)
    const startMenu = ref(false)
    const endMenu = ref(false)
    const msg = ref('Formu doldurduktan sonra ara butonuna basın')
    const tableItems = ref([])
    const tableFooter = ref([])
    const customer = ref(null)
    const docTypes = ref(5)
    const customerSearch = ref(null)
    const customerItems = ref([])
    const customerLoading = ref(false)

    const download = (docnum, doctype) => {
      dialog.value = true
      dialogPersistent.value = true
      dialogNum.value = docnum
      const param = {
        method: 'getInvContent',
        doctype,
        docnum,
      }

      store
        .dispatch('postMethod', param)
        .then(response => {
          if (response.error === 0) {
            iframe.setAttribute('id', 'print-iframe')
            iframe.width = '100%'
            iframe.height = '700'
            iframe.srcdoc = ''
            iframe.srcdoc = response.content

            document.getElementById('download').appendChild(iframe)
            document.getElementById('print-iframe').contentWindow.print()
            dialogPersistent.value = false
          } else {
            dialog.value = false
          }
        })
        .catch(error => {
          dialog.value = false
          console.log(error)
        })
    }

    const title = ref('')

    const detail = (docnum, doctype) => {
      console.log('1')
      dialogDetail.value = true
      dialogPersistent.value = true
      dialogNum.value = docnum
      const param = {
        method: 'getCaniasOrderDetail',
        doctype,
        docnum,
      }

      store
        .dispatch('postMethod', param)
        .then(response => {
          if (response.error === 0) {
            dialogPersistent.value = false
            detailItems.value = response.detail
            console.log('3')
          } else {
            dialogDetail.value = false
            console.log('4')
            console.log(response)
          }
        })
        .catch(error => {
          dialogDetail.value = false
          console.log(error)
          console.log('5')
        })
      console.log('6')
    }

    const totalDocumentsListTable = ref(0)
    const ERPSTATETXT = ref('')
    const loading = ref(false)
    const loading1 = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const selectedRows = ref([])
    const json_data = ref([])
    const dil = ref('')
    const DOCUMENTS_APP_STORE_MODULE_NAME = 'app-documents'

    if (!store.hasModule(DOCUMENTS_APP_STORE_MODULE_NAME)) {
      store.registerModule(DOCUMENTS_APP_STORE_MODULE_NAME, documentsStoreModule)
    }

    onMounted(() => {
      fetchDocuments()
      dil.value = localStorage.getItem('lang')
    })

    const fetchDocuments = () => {
      isFilterUserSidebarActive.value = false
      tableItems.value = []
      tableFooter.value = []
      msg.value = 'Arama yapılıyor lütfen bekleyin'
      loading.value = true
      const fetchDocumentsData = {
        method: 'getOpenOrders',
      }

      store
        .dispatch('app-documents/fetchDocuments', fetchDocumentsData)
        .then(response => {
          if (response.detail) {
            response.detail.forEach(item => {
              item.MATERIAL = "'" + item.MATERIAL
              item.SPRICE = "'" + item.SPRICE

              //console.log(item.MATERIAL)
            })
            tableItems.value = response.detail

            tableItems.value.forEach(item => {
              item.AVAILQTY = item.AVAILQTY.toLocaleString('tr-TR')
            })

            json_data.value = response.detail

            totalDocumentsListTable.value = Number(response.detail.length)
            loading.value = false
          } else {
            loading.value = false
            Vue.swal({
              title: 'Veri Yok',

              width: '350px',
              icon: 'warning',
              background: '#FFB400',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#987c3d',
            })
          }
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message === "Cannot read properties of null (reading 'length')" ? 'Sistem Hatası' : e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }

    return {
      tableColumns1,
      fetchDocuments,
      detail,
      title,
      customer,
      tableColumns,
      tableItems,
      json_data,
      dil,
      tableFooter,
      loading,
      loading1,
      selectedRows,
      ERPSTATETXT,
      trackingListTable1,
      options,
      msg,
      statusFind,
      totalDocumentsListTable,
      isFilterUserSidebarActive,
      docTypes,
      filter,
      download,

      // icons
      icons: {
        mdiDownload,
        mdiFilter,
        mdiMagnify,
        mdiSend,
        mdiEye,
        mdiFileDocument,
        mdiClose,
      },
    }
  },
  data() {
    return {
      dataTableHeight: '',
      dataTableHeight1: '',

      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 140 : 87,
        footer: this.$vuetify.breakpoint.smAndDown ? 47 : 47,
      },

      json_fields_en: {
        'Order Type': 'DOCTYPE',
        Status: 'ERPSTATETXT',
        'Order No': 'DOCNUM',
        'Item No': 'ITEMNUM',
        'Prdouct ': 'MATERIAL',
        'Prdouct Detail': 'MTEXT',
        'Open Order Amount ': 'AVAILQTY',
        'Recortd Date ': 'CREATEDAT',
        'Customer Requested Date': 'DESDLVDATE',
        'Date Given To Customer': 'PRODDATE',
        'Customer Product Code ': 'HTKCUSMAT',
        'Customer Order No': 'CUSTORDERNUM',
        'Document Price ': 'SPRICE',
        'Document Price Unit ': 'PRICEFACTOR',
        'Document Grand Total': 'GRANDTOTALITEM',
        'Document Currency ': 'CURRENCYITEM',
      },

      json_fields: {
        'Sipariş Tipi': 'DOCTYPE',
        Durum: 'ERPSTATETXT',
        'Sipariş No': 'DOCNUM',
        'Kalem No': 'ITEMNUM',
        'Malzeme ': 'MATERIAL',
        'Malzeme Açıklaması': 'MTEXT',
        'Açık Sipariş Miktarı': 'AVAILQTY',
        'Kayıt Tarihi ': 'CREATEDAT',
        'Müşterinin İstediği Tarih': 'DESDLVDATE',
        'Müşteriye Verilen Tarih ': 'PRODDATE',
        'Müşteri Ürün Kodu ': 'HTKCUSMAT',
        'Müşteri Sipariş No ': 'CUSTORDERNUM',
        'Belge Fiyat ': 'SPRICE',
        'Belge Fiyat Birim ': 'PRICEFACTOR',
        'Belge Genel Toplam	 ': 'GRANDTOTALITEM',
        'Belge Para Birimi ': 'CURRENCYITEM',
      },

      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize() {
      this.itemsPerPage = Math.ceil(window.innerHeight / 100) + 4
      this.dataTableHeight = window.innerHeight - 90 - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss"></style>
